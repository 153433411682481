import {
  getRemoteConfig as getFirebaseRemoteConfig,
  fetchAndActivate,
  getAll,
  isSupported,
} from 'firebase/remote-config'
import { toBoolean } from 'fitify-utils/src/properties'
import { useEffect, useState, createContext } from 'react'

type RemoteConfigAppId =
  | 'landing'
  | 'landing-hc'
  | 'onboarding'
  | 'onboarding-hc'

async function importRemoteConfigAppDefaults(app: RemoteConfigAppId) {
  try {
    const module = await import(`../config/remoteConfigDefaults-${app}.json`)
    return module.default as Promise<RemoteConfig>
  } catch (error) {
    console.error('Error importing config:', error)
    return {}
  }
}

export const useRemoteConfig = ({
  app,
}: {
  app: RemoteConfigAppId
}): RemoteConfig => {
  const isRemoteConfigFetchEnabled = toBoolean(
    process.env.NEXT_PUBLIC_REMOTE_CONFIG_FETCH_ENABLED
  )

  const [config, setConfig] = useState<RemoteConfig>({})

  useEffect(() => {
    const fetchData = async () => {
      const remoteConfigDefaults = await importRemoteConfigAppDefaults(app)
      setConfig(remoteConfigDefaults)

      try {
        if (isRemoteConfigFetchEnabled) {
          const data = await getRemoteConfig()
          const remoteConfigData =
            Object.keys(data).length > 0 ? data : remoteConfigDefaults

          setConfig(remoteConfigData)
        } else {
          setConfig(remoteConfigDefaults)
        }
      } catch {
        setConfig(remoteConfigDefaults)
      }
    }

    void fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRemoteConfigFetchEnabled])

  return config
}

export async function getRemoteConfig(): Promise<Record<string, any>> {
  const isRemoteConfigSupported = await isSupported()

  // If remote config is not supported, return empty object
  if (!isRemoteConfigSupported) {
    return {}
  }

  const firebaseRemoteConfig = getFirebaseRemoteConfig()

  await fetchAndActivate(firebaseRemoteConfig)
  const configParams = getAll(firebaseRemoteConfig)

  const remoteConfigProps: RemoteConfig = {}
  for (const key of Object.keys(configParams)) {
    const value: any = configParams[key]
    remoteConfigProps[key] = value._value
  }

  return remoteConfigProps
}

type Await<T> = T extends Promise<infer U> ? U : never

export type RemoteConfig = Await<ReturnType<typeof getRemoteConfig>>
export const RemoteContext = createContext<RemoteConfig>({} as RemoteConfig)
